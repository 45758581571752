/* TAIWO ADETAYO CSS @taiwoadetayo@gmail.com */

body {
  font-family: 'Open Sans', sans-serif !important;
}

.loginBG, body{
  background: #f2f2f2;
}
.appname {
  font-size: 14px;
  width: 160px;
  margin-top: 11px;
  font-weight: 600;
  margin-left: 7px;
  border-left: 1px solid #f5b7b747;
  padding-left: 7px;
  padding-top: 37px;
  /* color: #6137fb; */
}
.mr-13{margin-right: 18px;}
.auth-logo-area{
  box-shadow: -1px -1px 20px 120px #eaeaeaf0;
  background: #eaeaea;
}

.table {
  --bs-table-striped-bg: rgb(255 255 255) !important;
}


.page-lead-wrap{
  /* border-bottom: 1px solid #f6f5f5; */
  padding-bottom: 10px;
  box-shadow: 2px 4px 250px 20px #e2e0e0;
  background: #fff;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 60px !important;
}
.page-lead-wrap nav{
  font-size: 12px;
}
.page-lead-wrap nav a{
  text-decoration: none;
}

a{
  color: #3266fd;
}
.bold-500{
  font-weight: 500;
}

.form-group{
  position: relative;
}

.input-icon{
  position: absolute;
  top: 32px;
  right: 5px;
  /* background: #fff; */
  padding: 10px;
  color: #998e8e;
}
.input-feedback{
  position: absolute;
  top: 2px;
  right: 28px;
  /* background: #fff; */
  padding: 10px;
  color: #998e8e;
  border-left: 1px solid #f2f2f2;
  height: 38px;
}
.login-side-wrap{
  width: 70%;
}
.form-aside{
  height: 100vh;
  background: #fff;
  box-shadow: #2e2e2e14 1px 0px 5px 1px;
  position: relative;
}
.login-lead{
  margin-top:30%;
  font-size: 50px;
  font-weight: 800;
}

.font-12{
  font-size: 12px !important;
}
.input-font{
  font-size: 12px !important;
  padding: 10px 20px;
  border-radius: 2px;
}

.border-radius-sm{
  border-radius: 2px;
}
.btn-primary {
  background-color: #0075ff !important;
}
.form-group span{
  font-size: 12px;
}
.input-overlay{
  padding: 0 8px;
  background: #fff;
  top: 10px;
  left: 8px;
  position: relative;
  font-size: 12px !important;
  font-weight: 500;
  border-radius: 5px;
}

.copyright-area{
  font-size: 12px;
  color:#4e4e4e;
  background:#fff;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom:20px;

  position: absolute;
  bottom: 0;
  z-index: 1000;
  left: 5%;
  right: 5%;
}

.swal2-styled.swal2-confirm {
  background-color: #1e3d98 !important;
}
small{
  font-size: 12px !important;
}
.bell-value{
  background: red;
  padding: 3PX 8PX;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  right: 15px;
  top: -15px;
}
.bell-icon{
  color:red;
  font-size:25px;
}
.dropdown-menu-light{
  color:#000 !important;
  /* left: -60px !important; */
  font-size: 14px;
}
.dropdown-toggle.nav-link{
  text-align: right !important;
  font-size: 14px;
  font-weight: 500;
}
.bell-wrap{
  top: 15px;
  position: relative;
}
.page-lead{
  font-weight: 600;
  font-size: 18px;
}

.swal2-timer-progress-bar {
  background: rgba(230, 42, 42, 0.532) !important;
}



/* side navigation desktop & mobile */
.side-nav-wrap{
  width: 230px;
  background: rgb(255, 255, 255);
  height: 100%;
  border-right: 1px solid rgba(216, 216, 216, 0.32);
  padding: 0px;
  position: fixed;
}
.side-nav-area ul{
  padding: 0;
}
.side-nav-area ul li{
  list-style: none;
  width: 100%;
  text-decoration: none;
  display: block;
  font-size: 14px;
  font-weight: 600;
  transition: all 1s ease;
  border-bottom: 1px solid #f2f2f247;
}
.side-nav-area ul li a, .side-nav-area ul li div{
  text-decoration: none;
  text-decoration: none;
  padding: 7px 15px;
  width: 100%;
  display: block;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  transition: all 1s ease;
  text-transform: uppercase;
}
.side-nav-area ul li.active {
  background: #2e76d4 !important;
  color: #fff !important;
}
.side-nav-area ul li.active > a, .side-nav-area ul li.active > div{
  color: #fff;
}
.side-nav-area ul li.active svg, .side-nav-area ul li svg{
  margin-right: 20px;
  fill: #fff;
  top: -3px;
  position: relative;
  height: 1.1rem;
}
.side-nav-area ul li svg{
  fill: #383838;
}
.side-nav-area ul li.active svg{
  fill: #fff;
}
.side-nav-area ul li a, .side-nav-area ul li div{
  text-decoration: none;
  text-decoration: none;
  padding: 14px 25px;
  width: 100%;
  display: block;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  transition: all 1s ease;
  text-transform: uppercase;
}
.side-nav-area ul li a, .side-nav-area ul li div{
  transition: all 0.5s ease-in-out;
  position: relative;
}
.side-nav-area ul li a::before , .side-nav-area ul li div::before{
  content: attr(data-item);
  transition: 0.5s;
  background: rgb(7 38 199 / 5%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0;
  overflow: hidden;
}
.side-nav-area ul li a:hover::before, .side-nav-area ul li div:hover::before {
  width: 100%;
  transition: all 0.5s ease-in-out;
}



/* top nav area */
.user-icon-svg-topnav {
  fill: #333;
  font-size: 22px;
  margin-right: 5px;
  position: relative;
  top: -3px;
}
.inappname {
  color: #333;
  font-size: 16px;
  padding-left: 17px;
  padding-top: 20px;
}






.main-content-wrap{
  width: calc(100% - 230px);
  background: rgb(247, 247, 247);
  padding: 0px;
  margin-left: 230px;
}
.bolder{
  font-weight: 500;
}
.bold{
  font-weight: 600;

}
.info-txt{
  background: #fff2acb8;;
  padding: 2px 10px;
  font-size: 12px;
  border-left: 2px solid black;
  border-radius: 0 2px 2px 0;
}
.uicheckbox{
  width: 20px;
  height: 18px;
}
legend{ float: none; padding: inherit; }

.input-currency{
  position: absolute;
  top: 35px;
  left: 14px;
  padding-right: 10px;
  border-right: 1px solid #ced4da;
  font-size: 12px;
}
input.form-control:disabled {
  /* background-color: #f2f2f2 !important; */
  opacity: 1;
  cursor: not-allowed;
  border: 1px solid #2f0a0a1c;
  /* box-shadow: -2px 2px 3px 1px #822a2a12; */
}
.fixed-top-nav{
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(216, 216, 216, 0.32);
  box-shadow: rgb(242 242 242) 2px 0px 2px 2px;
  position: fixed;
  right: 0;
  left: 230px;
  z-index: 1000;
}

.form-control:focus {  
  transition: all .5s ease;
}
button:hover {
  transition: all 1s ease;
  box-shadow: -1px 0px 7px 2px #0726c71c;
}
button{
  transition: all 1s ease;
}
.lead-txt{
  background: #2e76d4;
  color: #fff;
  border-radius: 5px 5px 0 0;
}
.page-name{
  background: red;
  padding: 4px 26px;
  border-radius: 0 8px 0 0;
}

.css-b62m3t-container {
  font-size: 12px !important;
  font-weight: 500;
}
.css-1s2u09g-control {
  border-radius: 1px !important;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1s2u09g-control:hover, .css-1s2u09g-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #0075ff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #ff000056;
  transition: all .5s ease;
}

.css-1pahdxg-control {
  border-color: #f2f2f2 !important;
  border-radius: 1px !important;
  box-shadow: none !important;
}
.css-26l3qy-menu{
  margin-top: 2px !important;
}
input[type="checkbox"]{
  width:17px;
  height: 17px;
  border-radius: 0;
}
input[type="checkbox"]:checked {
  animation: effect 250ms ease-in;
  transition: all .5s ease;
  box-shadow: -1px 0px 6px 0.25rem rgb(13 110 253 / 16%);

}
thead tr{
  font-size: 14px;
  font-weight: 400 !important;
}

thead th {
  border: 1px solid #007bc2 !important;
  font-weight: 500;
  background: #007bc2 !important;
}

.bank-account-item{
  background: #f2f2f225;
  padding: 0px 20px 15px;
  border: 1px solid #ced4da;
  position: relative;
}

.bank-account-item.active:has(.bankCheckbox:checked){
  border: 2px solid #007bc2 !important
}

.bank-checked{
  position: absolute;
  top: -10px;
  right: -7px;
  background: #007bc2;
  color: #fff;
  padding: 8px;
}
.bankCheckbox {
  position: absolute;
  bottom: -12px;
  right: -12px;
  border: 1px solid #007bc2;
  color: #fff;
  height: 30px !important;
  width: 28px !important;
}
.bankCheckbox:checked{
  background: #0075ff;
}

.bankCheckbox:checked + label::after {
  content: 'Bank Selected';
  color:#fff;
  position: absolute;
  right: 19px;
  bottom: -16px;
  background: #0075ff;
  color: #fff !important;
  padding: 7px 40px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 2px;
}
.bankCheckbox + label::after {
  content: 'Click to select';
  color: #000;
  position: absolute;
  right: 19px;
  bottom: -16px;
  background: #f0f0f0;
  padding: 7px 40px;
  font-size: 12px;
  border: 1px solid #f2f2f2;
  font-weight: 500;
  border-radius: 2px;
}
.savedraftbtn{
  background: #fff !important;
  border: 2px solid blue !important;
  color: blue !important;
  font-weight: 600 !important;
}
.mpr-0{
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.info-alert{
  background: rgb(242 242 242 / 91%);
  border-left: 3px solid #007bc2;
  padding: 9px;
  margin-top: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
}

button:disabled {
  background: #eeeded !important;
  border: 1px solid #d3caca !important;
  color: #a99f9f !important;
}
.bg-light{  
  border: 1px solid #d3caca !important;
  background: #fff !important;
}

.btn-sm{
  font-size: 10px !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  background: #f4f3f3 !important;
  border: 1px solid #cdc7c7 !important;
  color: #a99f9f !important;
}
a.sidenav_disabled {
  pointer-events: none;
  cursor: default;
}




.stepper {
  display: flex;
  padding: 0;
  width: 100%;
  list-style: none;
  position: relative;
}
.stepper::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  content: "";
  width: calc(100% - 20px);
  background: #f4f4f4;
}
.stepper__item {
  flex: auto;
  /* padding: 10px 8px 10px 30px; */
  padding: 10px 15px 10px 24px;
  font-size: 14px;
  background: repeating-linear-gradient(-65deg, #fff, #fff 20px, #fcfcfc 20px, #fcfcfc 40px);
  margin: 0 0 0 0px;
  -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  align-items: center;
  display: flex;
  font-weight: 500;
}
.font-14, .notification-message{
  font-size: 14px;
}
.stepper__item.current {
  background: #075dcd;
  color: #fff;
}
.stepper__item.complete {
  background: #5096f2;
  color: #fff;
}
.stepper__item.terminate {
  background: #ee372c !important;
  color: #fff;
}
.stepper__item.success {
  background: green !important;
  color: #fff;
}


.stepper__item:first-child {
  /* padding: 20px; */
  /* -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%); */
}
.stepper__item:last-child {
  -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
}




.checkBtn{
  border: 1px solid #007bc2;
  background: #fff !important;
  color: #007bc2 !important;

}

.borderTop{border-top: 2px solid #007bc2;border-radius: 0 3px 0;}

tbody, td, tfoot, th, thead, tr {
  font-weight: 500;
}

.truncate {
  max-width: 100px;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}